<template>
  <transition name="fade-transform" mode="out-in">
    asdasdasd
    <router-view :key="this.$route.path" />
  </transition>
</template>

<script>
export default {};
</script>

<style>
</style>